import React, { useState } from 'react';
import { Button, Input, DatePicker, Col, Modal } from 'antd';

import { PageOptionsModel } from '@app/domain/PageMetaModel';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { notificationController } from '@app/controllers/notificationController';
import { getErrorMessage } from '@app/utils/utils';
import { PageTitle } from '../common/PageTitle/PageTitle';
import * as S from '@app/pages/uiComponentsPages//UIComponentsPage.styles';
import { createCustomerNotificationBaseRequest } from '../../api/firebase.api';
import { createCustomerNotification } from '../../api/firebase.api';

const initialCreateCustomerNotification: createCustomerNotificationBaseRequest = {
  title: '',
  body: null,
};
const { RangePicker } = DatePicker;
const timezoneOffset = new Date().getTimezoneOffset();
export const Firebase: React.FC = () => {
  const [createFirebaseData, setCreateFirebaseData] = useState<createCustomerNotificationBaseRequest>(
    initialCreateCustomerNotification,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [loading, setLoading] = useState<boolean>(false);

  const handleOpenModal = () => {
    if (!createFirebaseData.title || !createFirebaseData.body) {
      notificationController.error({
        message: 'Create Firebase',
        description: 'Title and Body are required',
      });
      return;
    }
    if (createFirebaseData.title.length > 200) {
      notificationController.error({
        message: 'Create Firebase',
        description: 'Title is too long',
      });
      return;
    }
    if (createFirebaseData.body.length > 500) {
      notificationController.error({
        message: 'Create Firebase',
        description: 'Body is too long',
      });
      return;
    }
    setIsModalOpen(true);
  };
  const handleCreateFirebase = async () => {
    setLoading(true);
    try {
      const response = await createCustomerNotification(createFirebaseData);
      if (isMounted) {
        notificationController.success({
          message: t('common.success'),
        });
        setCreateFirebaseData(initialCreateCustomerNotification);
      }
    } catch (error) {
      if (isMounted) {
        notificationController.error({
          message: `Create Firebase: ${createFirebaseData.title}`,
          description: getErrorMessage(error),
        });
      }
    } finally {
      // alert('Create Firebase');
      setIsModalOpen(false);
      setLoading(false);
    }
  };

  return (
    <>
      <PageTitle>{t('common.firebase')}</PageTitle>
      <Col>
        <S.Card title={t('common.firebase')}>
          <S.InputsWrapper>
            <Input
              placeholder={'Title'}
              onChange={(e) => setCreateFirebaseData({ ...createFirebaseData, title: e.target.value })}
            />
            <Input
              placeholder={'Body'}
              onChange={(e) => setCreateFirebaseData({ ...createFirebaseData, body: e.target.value })}
            />
            <Button type="primary" onClick={() => handleOpenModal()}>
              {t('common.submit')}
            </Button>
          </S.InputsWrapper>
        </S.Card>
      </Col>
      <Modal
        title={'Confirm send notification'}
        centered
        open={isModalOpen}
        onOk={() => handleCreateFirebase()}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        confirmLoading={loading}
      ></Modal>
    </>
  );
};

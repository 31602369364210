import { ReactNode } from 'react';

export interface PageMetaModel {
  current: number;

  pageSize: number;

  total: number;

  page_count: number;

  has_previous_page: boolean;

  has_next_page: boolean;
}
export enum PageOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface PageOptionsModel {
  sortOrder?: PageOrder;
  sortColumn?: string;
  filterValue?: string;
  filterColumn?: string;
  current?: number;
  pageSize?: number;
  search?: string;
}

import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Input,
  Col,
  Modal,
  Form,
  Row,
  Image,
  Spin,
  TableColumnsType,
  Table,
  Select,
  TablePaginationConfig,
  Tooltip,
} from 'antd';

import { PageOptionsModel, PageOrder } from '@app/domain/PageMetaModel';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { notificationController } from '@app/controllers/notificationController';
import { getErrorMessage } from '@app/utils/utils';
import { PageTitle } from '../common/PageTitle/PageTitle';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import moment from 'moment';
import {
  GroupOrder,
  GroupOrderDisplaySetting,
  GroupResponse,
  getGroupOrders,
  updateGroupOrderDisplaySetting,
  updateGroupOrderDisplaySettingRequest,
} from '../../api/group.api';
import Search from 'antd/lib/input/Search';
import {
  ConstantConfigResponse,
  UpsertConstantConfigRequest,
  createConstantConfig,
  getConstantConfigs,
  updateConstantConfig,
  updateConstantConfigCache,
} from '../../api/constantConfig';
import TextArea from 'antd/lib/input/TextArea';

const initialPagination: PageOptionsModel = {
  current: 1,
  pageSize: 10,
};

const initialConstantConfig: UpsertConstantConfigRequest = {
  name: '',
  value: '',
  status: 1,
  type: 1,
};
const initialGroupOrderDisplaySetting: updateGroupOrderDisplaySettingRequest = {
  id: 0,
  displaySetting: {
    promoTags: '',
  },
};

const shopUrl = process.env.REACT_APP_SHOP_URL;

export const ConstantConfig: React.FC = () => {
  const [tableData, setTableData] = useState<{
    data: ConstantConfigResponse[];
    pagination: PageOptionsModel;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: true,
  });
  const [upsertConstantConfigData, setUpsertConstantConfigData] =
    useState<UpsertConstantConfigRequest>(initialConstantConfig);

  const [updateId, setUpdateId] = useState<number>(0);

  const [upsertModalTitle, setUpsertModalTitle] = useState<string>('Create Constant Config');
  const [isUpdateCacheModalOpen, setIsUpdateCacheModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [loading, setLoading] = useState<boolean>(false);
  const [isUpsertModalOpen, setIsUpsertModalOpen] = useState<boolean>(false);
  const [form] = Form.useForm();

  const fetch = useCallback(
    (pagination: PageOptionsModel) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getConstantConfigs({ params: pagination })
        .then((res) => {
          if (isMounted.current) {
            const newPagination: TablePaginationConfig & { search?: string } = {
              ...res.meta,
              showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
              showQuickJumper: true,
              // search: pagination.search,
            };
            if (pagination.search) {
              newPagination.search = pagination.search;
            }
            setTableData({ data: res.data, pagination: newPagination, loading: false });
          }
        })
        .finally(() => {
          return setTableData((tableData) => ({ ...tableData, loading: false }));
        });
    },
    [isMounted],
  );
  useEffect(() => {
    getConstantConfigs({ params: tableData.pagination })
      .then((res) => {
        const pagination = {
          ...res.meta,
          showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
          showQuickJumper: true,
        };
        setTableData({ data: res.data, pagination: pagination, loading: false });
      })
      .finally(() => {
        return setTableData((tableData) => ({ ...tableData, loading: false }));
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.debug('tableData.pagination', tableData.pagination);
  // }, [tableData.pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableChange = (
    tablePagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ConstantConfigResponse> | SorterResult<ConstantConfigResponse>[],
  ) => {
    const pagination: PageOptionsModel = {
      current: tablePagination.current,
      pageSize: tablePagination.pageSize,
    };

    if ('order' in sorter && sorter.order == 'ascend') {
      pagination.sortColumn = sorter.columnKey?.toString();
      pagination.sortOrder = PageOrder.ASC;
    } else if ('order' in sorter && sorter.order == 'descend') {
      pagination.sortColumn = sorter.columnKey?.toString();
      pagination.sortOrder = PageOrder.DESC;
    }
    if (tableData.pagination.search) {
      pagination.search = tableData.pagination.search;
    }
    if (
      tablePagination.current != tableData.pagination.current ||
      tablePagination.pageSize != tableData.pagination.pageSize ||
      Object.keys(sorter).length > 0
    ) {
      fetch(pagination);
    }
  };

  const handleUpsertConstantConfig = async () => {
    await form.validateFields();
    setLoading(true);
    let message = 'Create Constant Config';
    if (updateId > 0) {
      message = 'Update Constant Config: ' + updateId;
    }
    try {
      if (updateId > 0) {
        await updateConstantConfig(updateId, upsertConstantConfigData);
      } else {
        await createConstantConfig(upsertConstantConfigData);
      }
      notificationController.success({
        message: 'Success',
        description: message,
      });
      setIsUpsertModalOpen(false);
      fetch(tableData.pagination);
      setLoading(false);
      setUpdateId(0);
      setUpsertConstantConfigData(initialConstantConfig);
      form.resetFields();
    } catch (error) {
      notificationController.error({
        message: 'Error: ' + message,
        description: getErrorMessage(error),
      });
      setLoading(false);
    }
  };

  const handleUpdateCache = async () => {
    setLoading(true);
    try {
      await updateConstantConfigCache();
      notificationController.success({
        message: 'Success!',
      });
    } catch (errInfo) {
      notificationController.error({
        message: 'Fail',
        description: getErrorMessage(errInfo),
      });
    } finally {
      setLoading(false);
      setIsUpdateCacheModalOpen(false);
    }
  };

  const onSearch = (value: string) => {
    const pagination: PageOptionsModel = {
      ...tableData.pagination,
      current: 1,
    };
    if (value === '') {
      delete pagination.search;
    } else {
      pagination.search = value;
    }
    setTableData((tableData) => ({ data: tableData.data, pagination: pagination, loading: true }));
    fetch(pagination);
  };

  const columns: TableColumnsType<ConstantConfigResponse> = [
    {
      title: 'ID',
      key: 'id',
      // sorter: true,
      render: (text, record) => record.id,
    },
    {
      title: 'Name',
      key: 'name',
      render: (text, record) => (
        <Tooltip title={record.name}>
          <div
            style={{
              width: 150, // 设置你需要的最大宽度
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {record.name}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Value',
      key: 'value',
      render: (text, record) => (
        <Tooltip title={record.value}>
          <div
            style={{
              width: 200, // 设置你需要的最大宽度
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {record.value}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Type',
      key: 'type',
      render: (text, record) => {
        if (record.type === 1) {
          return 'FRONTEND';
        } else if (record.type === 2) {
          return 'BACKEND';
        } else if (record.type === 3) {
          return 'APP';
        } else {
          return 'UNKNOWN';
        }
      },
    },
    {
      title: 'Status',
      key: 'status',
      render: (text, record) => {
        if (record.status === 1) {
          return 'ACTIVE';
        } else if (record.status === 0) {
          return 'INACTIVE';
        } else {
          return 'UNKNOWN';
        }
      },
    },
    {
      title: t('common.createdAt'),
      key: 'created_at',
      render: (text, record) => moment(record.created_at).format('LLL'),
    },
    {
      title: t('common.updatedAt'),
      key: 'updated_at',
      render: (text, record) => moment(record.updated_at).format('LLL'),
      sorter: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            setUpdateId(record.id);
            setUpsertModalTitle('Update Constant Config');
            setUpsertConstantConfigData({ ...record });
            form.setFieldsValue({ ...record });
            setIsUpsertModalOpen(true);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <>
      <Search placeholder="name" allowClear onSearch={onSearch} style={{ width: 300 }} size="small" />
      <Button
        type="primary"
        size="small"
        onClick={() => {
          setUpsertModalTitle('Create Constant Config');
          setIsUpsertModalOpen(true);
          form.setFieldsValue({ ...initialConstantConfig });
        }}
        style={{ marginBottom: 10, marginLeft: 10 }}
      >
        Create
      </Button>
      <Button
        type="default"
        size="small"
        onClick={() => setIsUpdateCacheModalOpen(true)}
        style={{ marginBottom: 10, marginLeft: 10 }}
      >
        Update Cache
      </Button>
      <PageTitle>{t('common.firebase')}</PageTitle>
      <Table
        // components={{
        //   body: {
        //     cell: EditableCell,
        //   },
        // }}
        // rowClassName="editable-row"
        tableLayout="auto"
        columns={columns}
        dataSource={tableData.data}
        pagination={tableData.pagination}
        loading={tableData.loading}
        onChange={handleTableChange}
        // scroll={{ x: 800 }}
        bordered
      />

      <Modal
        title={upsertModalTitle}
        centered
        open={isUpsertModalOpen}
        onOk={() => handleUpsertConstantConfig()}
        onCancel={() => {
          setLoading(false);
          setIsUpsertModalOpen(false);
          if (updateId) {
            setUpdateId(0);
            setUpsertConstantConfigData(initialConstantConfig);
            form.resetFields();
          }
        }}
        confirmLoading={loading}
      >
        <Spin spinning={loading}>
          <Form name="basic" form={form}>
            <Form.Item label={'Name'} name="name" rules={[{ required: true, message: 'Please input name!' }]}>
              <Input
                placeholder="Name"
                value={upsertConstantConfigData.name}
                onChange={(e) =>
                  setUpsertConstantConfigData({
                    ...upsertConstantConfigData,
                    name: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item label={'Value'} name="value" rules={[{ required: true, message: 'Please input value!' }]}>
              <TextArea
                required
                placeholder={'Separated by commas'}
                value={upsertConstantConfigData.value}
                rows={4}
                onChange={(e) => {
                  console.log(e.target.value);
                  setUpsertConstantConfigData({
                    ...upsertConstantConfigData,
                    value: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item label={'Type'} name="type" rules={[{ required: true, message: 'Please select type!' }]}>
              <Select
                defaultValue={upsertConstantConfigData.type}
                style={{ width: 120 }}
                onChange={(value: number) => setUpsertConstantConfigData({ ...upsertConstantConfigData, type: value })}
                options={[
                  { value: 1, label: 'Frontend' },
                  { value: 2, label: 'Backend' },
                  { value: 3, label: 'APP' },
                ]}
              />
            </Form.Item>
            <Form.Item label={'Status'} name="status" rules={[{ required: true, message: 'Please select status!' }]}>
              <Select
                defaultValue={upsertConstantConfigData.status}
                style={{ width: 120 }}
                onChange={(value: number) =>
                  setUpsertConstantConfigData({ ...upsertConstantConfigData, status: value })
                }
                options={[
                  { value: 0, label: 'Inactive' },
                  { value: 1, label: 'Active' },
                ]}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      <Modal
        title={'Confirm update constant config cache ?'}
        centered
        open={isUpdateCacheModalOpen}
        onOk={() => handleUpdateCache()}
        onCancel={() => {
          setIsUpdateCacheModalOpen(false);
        }}
        confirmLoading={loading}
      ></Modal>
    </>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Firebase } from '../../components/firebase/Firebase';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const FirebasePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.firebase')}</PageTitle>
      <Firebase />
    </>
  );
};

export default FirebasePage;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Group } from '../components/group/Group';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const GroupPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>Group</PageTitle>
      <Group />
    </>
  );
};

export default GroupPage;

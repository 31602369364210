import { httpApi } from '@app/api/http.api';

export interface createCustomerNotificationBaseRequest {
  title: string;
  body: string | null;
  data_type?: string;
  data_type_value?: string;
}

export interface createCustomerTokensBaseRequest {
  fcm_tokens: string[];
  title: string;
  body: string | null;
  data_type?: string | null;
  data_type_value?: string | null;
}

export const createCustomerNotification = (Payload: createCustomerNotificationBaseRequest): Promise<void> =>
  httpApi
    .post<createCustomerNotificationBaseRequest>(`v1/admin/customer/send-notification-to-all-customer`, Payload)
    .then(({ data }) => {
      console.log(data);
    });

export const createTokensNotification = (Payload: createCustomerTokensBaseRequest): Promise<void> =>
  httpApi.post<createCustomerTokensBaseRequest>(`v1/admin/customer/multiple-notification`, Payload).then(({ data }) => {
    console.log(data);
  });

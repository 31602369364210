import { WithChildrenProps } from '@app/types/generalTypes';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export const PageTitle: React.FC<WithChildrenProps> = ({ children }) => {
  return (
    <Helmet>
      {/* <script
        defer
        data-domain={process.env.NODE_ENV === 'production' ? 'dev-airmart-dashboard.goairmart.com' : 'dev-airmart-dashboard.goairmart.com'}
        src="https://analytics.goairmart.com/js/script.js"
      ></script>
      <script>
        {`window.plausible =
 window.plausible ||
 function () {
 (window.plausible.q = window.plausible.q || []).push(arguments);
 };`}
      </script> */}
      <title>{children} | Arimart Admin Dashboard</title>
    </Helmet>
  );
};

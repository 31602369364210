import { httpApi } from '@app/api/http.api';
import { PageMetaModel, PageOptionsModel } from '../domain/PageMetaModel';
export interface GetShortVideosRequest {
  params: PageOptionsModel;
}
export interface CreateShortVideoRequest {
  title: string;
  group_id: number;
  video: string;
  thumbnail: string;
}
export interface UpdateShortVideoRequest {
  id: number;
  title: string;
  group_id: number;
  status: number;
}

export interface UpdateVideoFileRequest {
  id: number;
  group_id: number;
  video: string;
}

export interface UpdateThumbnailFileRequest {
  id: number;
  group_id: number;
  thumbnail: string;
}

export interface ShortVideo {
  id: number;
  seller_id: string;
  title: string;
  group_id: number;
  video: string;
  thumbnail: string;
  total_view: number;
  like: number;
  total_click_buy: number;
  status: number;
  group_name: string;
  seller_name: string;
  seller_image: string;
  created_at: Date;
  updated_at: Date;
}

export interface GetShortVideosRequestResponse {
  meta: PageMetaModel;
  data: ShortVideo[];
}

export interface CreatePresignedUrlRequest {
  group_id: number;
  content_type: string;
  extension: string;
}

export interface CreatePresignedUrlResponse {
  group_id: number;
  s3_upload_url: string;
  s3_key: string;
  expires_at: number;
}

export const getShortVideos = (pagePayload: GetShortVideosRequest): Promise<GetShortVideosRequestResponse> =>
  httpApi.get<GetShortVideosRequestResponse>('v1/admin/short-videos', { ...pagePayload }).then(({ data }) => {
    console.log(data);
    return data as GetShortVideosRequestResponse;
  });

export const createShortVideo = (Payload: CreateShortVideoRequest): Promise<ShortVideo> =>
  httpApi.post<ShortVideo>(`v1/admin/short-videos`, Payload).then(({ data }) => {
    console.log(data);
    return data;
  });

export const updateShortVideo = (Payload: UpdateShortVideoRequest): Promise<ShortVideo> =>
  httpApi.put<ShortVideo>(`v1/admin/short-videos/${Payload.id}`, Payload).then(({ data }) => {
    console.log(data);
    return data;
  });

export const updateVideoFile = (Payload: UpdateVideoFileRequest): Promise<ShortVideo> =>
  httpApi.put<ShortVideo>(`v1/admin/short-videos/${Payload.id}/video-file`, Payload).then(({ data }) => {
    console.log(data);
    return data;
  });

export const updateThumbnailFile = (Payload: UpdateThumbnailFileRequest): Promise<ShortVideo> =>
  httpApi.put<ShortVideo>(`v1/admin/short-videos/${Payload.id}/thumbnail-file`, Payload).then(({ data }) => {
    console.log(data);
    return data;
  });

export const createPresignedUrl = (Payload: CreatePresignedUrlRequest): Promise<CreatePresignedUrlResponse> =>
  httpApi.post<CreatePresignedUrlResponse>(`v1/admin/short-videos/create-presigned-url`, Payload).then(({ data }) => {
    console.log(data);
    return data;
  });

export const uploadFileToS3 = (presignedUrl: string, file: File): Promise<CreatePresignedUrlResponse> =>
  httpApi
    .put<CreatePresignedUrlResponse>(presignedUrl, file, { headers: { 'Content-Type': file.type } })
    .then(({ data }) => {
      console.log(data);
      return data;
    });

import { httpApi } from '@app/api/http.api';
import { PageMetaModel, PageOptionsModel } from '../domain/PageMetaModel';

export interface GetConstantConfigRequest {
  params: PageOptionsModel;
}

export interface UpsertConstantConfigRequest {
  name: string;
  value: string;
  status: number;
  type: number;
}

export interface ConstantConfigResponse {
  id: number;
  name: string;
  value: string;
  status: number;
  type: number;
  created_at: Date;
  updated_at: Date;
}

export interface ConstantConfigsResponse {
  meta: PageMetaModel;
  data: ConstantConfigResponse[];
}

export const getConstantConfigs = (pagePayload: GetConstantConfigRequest): Promise<ConstantConfigsResponse> =>
  httpApi.get<ConstantConfigsResponse>('v1/admin/config', { ...pagePayload }).then(({ data }) => {
    // console.log(data);
    return data as ConstantConfigsResponse;
  });

export const createConstantConfig = (Payload: UpsertConstantConfigRequest): Promise<ConstantConfigResponse> =>
  httpApi.post<ConstantConfigResponse>(`v1/admin/config`, Payload).then(({ data }) => {
    console.log(data);
    return data;
  });

export const updateConstantConfig = (
  id: number,
  Payload: UpsertConstantConfigRequest,
): Promise<ConstantConfigResponse> =>
  httpApi.put<ConstantConfigResponse>(`v1/admin/config/${id}`, Payload).then(({ data }) => {
    return data;
  });

export const updateConstantConfigCache = (): Promise<void> => httpApi.delete('/v1/admin/config/cache');

import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Input,
  Col,
  Modal,
  Form,
  Row,
  Image,
  Spin,
  TableColumnsType,
  Table,
  Select,
  TablePaginationConfig,
} from 'antd';

import { PageOptionsModel } from '@app/domain/PageMetaModel';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { notificationController } from '@app/controllers/notificationController';
import { getErrorMessage } from '@app/utils/utils';
import { PageTitle } from '../common/PageTitle/PageTitle';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import {
  CreatePresignedUrlResponse,
  ShortVideo,
  createPresignedUrl,
  createShortVideo,
  CreateShortVideoRequest,
  getShortVideos,
  UpdateShortVideoRequest,
  updateShortVideo,
  UpdateVideoFileRequest,
  updateVideoFile,
  UpdateThumbnailFileRequest,
  updateThumbnailFile,
} from '../../api/shortVideos.api';
import axios from 'axios';
import moment from 'moment';
const s3Url = 'https://s3.us-west-2.amazonaws.com/airmart.image/';

const initialPagination: PageOptionsModel = {
  current: 1,
  pageSize: 20,
};
const initialShortVideo: CreateShortVideoRequest = {
  title: '',
  group_id: 0,
  video: '',
  thumbnail: '',
};
const initialUpdateShortVideo: UpdateShortVideoRequest = {
  id: 0,
  title: '',
  group_id: 0,
  status: 0,
};

const initialUpdateVideoFile: UpdateVideoFileRequest = {
  id: 0,
  group_id: 0,
  video: '',
};

const initialUpdateThumbnailFile: UpdateThumbnailFileRequest = {
  id: 0,
  group_id: 0,
  thumbnail: '',
};

export const ShortVideos: React.FC = () => {
  const [tableData, setTableData] = useState<{
    data: ShortVideo[];
    pagination: PageOptionsModel;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: true,
  });
  const [createShortVideoData, setCreateShortVideoData] = useState<CreateShortVideoRequest>(initialShortVideo);
  const [updateShortVideoData, setUpdateShortVideoData] = useState<UpdateShortVideoRequest>(initialUpdateShortVideo);
  const [updateVideoFileData, setUpdateVideoFileData] = useState<UpdateVideoFileRequest>(initialUpdateVideoFile);
  const [updateThumbnailFileData, setUpdateThumbnailFileData] =
    useState<UpdateThumbnailFileRequest>(initialUpdateThumbnailFile);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [isUpdateVideoModalOpen, setIsUpdateVideoModalOpen] = useState<boolean>(false);
  const [isUpdateThumbnailModalOpen, setIsUpdateThumbnailModalOpen] = useState<boolean>(false);
  const [isPreviewVideoModalOpen, setIsPreviewVideoModalOpen] = useState<boolean>(false);
  const [spinTip, setSpinTip] = useState<string>('');
  const [videoFile, setVideoFile] = useState<File>();
  const [videoUrl, setVideoUrl] = useState<string>('');
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [loading, setLoading] = useState<boolean>(false);
  const previewVideo = React.useRef<HTMLVideoElement>(null);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('');
  const [thumbnailFile, setThumbnailFile] = useState<File>();
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();

  const fetch = useCallback(
    (pagination: PageOptionsModel) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getShortVideos({ params: pagination })
        .then((res) => {
          if (isMounted.current) {
            const pagination = {
              ...res.meta,
              showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
              showQuickJumper: true,
            };
            // setTableData({ data: res.data, pagination: res.meta, loading: false });
            setTableData({ data: res.data, pagination: pagination, loading: false });
          }
        })
        .finally(() => {
          return setTableData((tableData) => ({ ...tableData, loading: false }));
        });
    },
    [isMounted],
  );
  useEffect(() => {
    getShortVideos({ params: tableData.pagination })
      .then((res) => {
        const pagination = {
          ...res.meta,
          showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
          showQuickJumper: true,
        };
        setTableData({ data: res.data, pagination: pagination, loading: false });
      })
      .finally(() => {
        return setTableData((tableData) => ({ ...tableData, loading: false }));
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableChange = (
    tablePagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ShortVideo> | SorterResult<ShortVideo>[],
  ) => {
    const pagination: PageOptionsModel = {
      current: tablePagination.current,
      pageSize: tablePagination.pageSize,
    };
    if (
      tablePagination.current != tableData.pagination.current ||
      tablePagination.pageSize != tableData.pagination.pageSize ||
      Object.keys(sorter).length > 0
    ) {
      fetch(pagination);
    }
  };

  const uploadToPresignedUrl = async (presignedUrl: string, file: File, filetype = 'video') => {
    // Upload file to pre-signed URL
    await axios.put(presignedUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        let message = '';
        if (filetype === 'video') {
          message = `Video Upload Progress: ${percentCompleted}%`;
        } else {
          message = `Thumbnail Upload Progress: ${percentCompleted}%`;
        }
        setSpinTip(message);
      },
    });
  };

  const handleCreateShortVideo = async () => {
    setLoading(true);
    await form.validateFields();
    // console.debug(createShortVideoData);
    // console.debug(videoFile);
    // console.debug(thumbnailFile);
    if (!createShortVideoData.title || !createShortVideoData.group_id || !videoFile || !thumbnailFile) {
      notificationController.error({
        message: 'Create Short Video',
        description: 'Title, Group Id and Video are required',
      });
      setLoading(false);
      return;
    }
    let videoPresignedUrl: CreatePresignedUrlResponse;
    try {
      const extension = videoFile.name.split('.').pop();
      videoPresignedUrl = await createPresignedUrl({
        group_id: createShortVideoData.group_id,
        content_type: videoFile.type,
        extension: extension ? extension : videoFile.type.split('/').pop() || '',
      });
    } catch (error) {
      notificationController.error({
        message: `Create Presigned Url: ${createShortVideoData.title}, ${createShortVideoData.group_id}`,
        description: getErrorMessage(error),
      });
      setLoading(false);
      return;
    }
    try {
      // console.debug(videoFile);
      // await uploadFileToS3(videoPresignedUrl.s3_upload_url, videoFile);
      setSpinTip(''); // Reset Spin Tip
      await uploadToPresignedUrl(videoPresignedUrl.s3_upload_url, videoFile, 'video');
      createShortVideoData.video = videoPresignedUrl.s3_key;
    } catch (error) {
      notificationController.error({
        message: `Upload File To S3: ${videoPresignedUrl.s3_key}`,
        description: getErrorMessage(error),
      });
      setLoading(false);
      return;
    }
    let thumbnailPresignedUrl: CreatePresignedUrlResponse;
    try {
      const extension = thumbnailFile.name.split('.').pop();
      thumbnailPresignedUrl = await createPresignedUrl({
        group_id: createShortVideoData.group_id,
        content_type: thumbnailFile.type,
        extension: extension ? extension : thumbnailFile.type.split('/').pop() || '',
      });
    } catch (error) {
      notificationController.error({
        message: `Create Presigned Url: ${createShortVideoData.title}, ${createShortVideoData.group_id}`,
        description: getErrorMessage(error),
      });
      setLoading(false);
      return;
    }
    try {
      setSpinTip(''); // Reset Spin Tip
      await uploadToPresignedUrl(thumbnailPresignedUrl.s3_upload_url, thumbnailFile, 'thumbnail');
      createShortVideoData.thumbnail = thumbnailPresignedUrl.s3_key;
    } catch (error) {
      notificationController.error({
        message: `Upload File To S3: ${thumbnailPresignedUrl.s3_key}`,
        description: getErrorMessage(error),
      });
      setLoading(false);
      return;
    }
    const res = await createShortVideo(createShortVideoData);
    const newData = [res, ...tableData.data];
    if (tableData.pagination.pageSize && newData.length > tableData.pagination.pageSize) {
      newData.pop();
    }
    setTableData({ ...tableData, data: newData });
    setLoading(false);
    setIsCreateModalOpen(false);
  };

  const handleUpdateShortVideo = async () => {
    setLoading(true);
    // await updateForm.validateFields();
    // console.debug(updateShortVideoData);
    if (updateShortVideoData.id === 0) {
      notificationController.error({
        message: 'Update Short Video',
        description: 'Id is required',
      });
      setLoading(false);
      return;
    }
    // setLoading(false);
    // return;
    try {
      const res = await updateShortVideo(updateShortVideoData);
      notificationController.success({
        message: `${t('common.update')}: ${updateShortVideoData.title}`,
      });
      const newData = [...tableData.data];
      const index = newData.findIndex((item) => updateShortVideoData.id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...res,
        });
      }
      setTableData({ ...tableData, data: newData });
      setUpdateShortVideoData(initialUpdateShortVideo);
    } catch (error) {
      notificationController.error({
        message: `Update Short Video: ${updateShortVideoData.id}`,
        description: getErrorMessage(error),
      });
    } finally {
      setLoading(false);
      setIsUpdateModalOpen(false);
    }
  };

  const handleUpdateVideoFile = async () => {
    setLoading(true);
    if (updateVideoFileData.id == 0 || !videoFile) {
      notificationController.error({
        message: 'Update Video File',
        description: 'Id and Video are required',
      });
      setLoading(false);
      return;
    }
    let videoPresignedUrl: CreatePresignedUrlResponse;
    try {
      const extension = videoFile.name.split('.').pop();
      videoPresignedUrl = await createPresignedUrl({
        group_id: updateVideoFileData.group_id,
        content_type: videoFile.type,
        extension: extension ? extension : videoFile.type.split('/').pop() || '',
      });
    } catch (error) {
      notificationController.error({
        message: `Create Presigned Url: ${updateVideoFileData.id} (Video Id)`,
        description: getErrorMessage(error),
      });
      setLoading(false);
      return;
    }
    try {
      // console.debug(videoFile);
      // await uploadFileToS3(videoPresignedUrl.s3_upload_url, videoFile);
      setSpinTip(''); // Reset Spin Tip
      await uploadToPresignedUrl(videoPresignedUrl.s3_upload_url, videoFile, 'video');
      updateVideoFileData.video = videoPresignedUrl.s3_key;
    } catch (error) {
      notificationController.error({
        message: `Upload File To S3: ${videoPresignedUrl.s3_key}`,
        description: getErrorMessage(error),
      });
      setLoading(false);
      return;
    }
    try {
      const res = await updateVideoFile(updateVideoFileData);
      notificationController.success({
        message: `${t('common.update')} Video: ${updateVideoFileData.id} (Video Id)`,
      });
      const newData = [...tableData.data];
      const index = newData.findIndex((item) => updateVideoFileData.id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...res,
        });
      }
      setTableData({ ...tableData, data: newData });
      setUpdateVideoFileData(initialUpdateVideoFile);
    } catch (error) {
      notificationController.error({
        message: `Update Short Video: ${updateShortVideoData.id}`,
        description: getErrorMessage(error),
      });
    } finally {
      setLoading(false);
      setIsUpdateVideoModalOpen(false);
    }
  };
  const handleUpdateThumbnailFile = async () => {
    setLoading(true);
    if (updateThumbnailFileData.id == 0 || !thumbnailFile) {
      notificationController.error({
        message: 'Update Thumbnail File',
        description: 'Id and Thumbnail are required',
      });
      setLoading(false);
      return;
    }
    let videoPresignedUrl: CreatePresignedUrlResponse;
    try {
      const extension = thumbnailFile.name.split('.').pop();
      videoPresignedUrl = await createPresignedUrl({
        group_id: updateThumbnailFileData.group_id,
        content_type: thumbnailFile.type,
        extension: extension ? extension : thumbnailFile.type.split('/').pop() || '',
      });
    } catch (error) {
      notificationController.error({
        message: `Create Presigned Url: ${updateThumbnailFileData.id} (Video Id)`,
        description: getErrorMessage(error),
      });
      setLoading(false);
      return;
    }
    try {
      // console.debug(videoFile);
      // await uploadFileToS3(videoPresignedUrl.s3_upload_url, videoFile);
      setSpinTip(''); // Reset Spin Tip
      await uploadToPresignedUrl(videoPresignedUrl.s3_upload_url, thumbnailFile, 'thumbnailFile');
      updateThumbnailFileData.thumbnail = videoPresignedUrl.s3_key;
    } catch (error) {
      notificationController.error({
        message: `Upload File To S3: ${videoPresignedUrl.s3_key}`,
        description: getErrorMessage(error),
      });
      setLoading(false);
      return;
    }
    try {
      const res = await updateThumbnailFile(updateThumbnailFileData);
      notificationController.success({
        message: `${t('common.update')} Thumbnail: ${updateThumbnailFileData.id} (Video Id)`,
      });
      const newData = [...tableData.data];
      const index = newData.findIndex((item) => updateThumbnailFileData.id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...res,
        });
      }
      setTableData({ ...tableData, data: newData });
      setUpdateThumbnailFileData(initialUpdateThumbnailFile);
    } catch (error) {
      notificationController.error({
        message: `Update Short Video: ${updateShortVideoData.id}`,
        description: getErrorMessage(error),
      });
    } finally {
      setLoading(false);
      setIsUpdateVideoModalOpen(false);
    }
  };
  const onChangeInputVideoFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    setVideoFile(file);
    setIsPreviewVideoModalOpen(true);
    setVideoUrl(URL.createObjectURL(file));
  };

  useEffect(() => {
    if (previewVideo.current) {
      previewVideo.current.src = videoUrl;
    }
  }, [previewVideo.current, videoUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeInputImageFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];
    setThumbnailFile(file);
    setThumbnailUrl(URL.createObjectURL(file));
    // setIsShowPreviewImage(true);
  };

  const columns: TableColumnsType<ShortVideo> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: t('common.title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Seller Name',
      dataIndex: 'seller_name',
      key: 'seller_name',
    },
    {
      title: 'Group Name',
      dataIndex: 'group_name',
      key: 'group_name',
    },
    {
      title: 'Group ID',
      dataIndex: 'group_id',
      key: 'group_id',
    },
    {
      title: 'Video',
      dataIndex: 'video',
      key: 'video',
      render: (thumbnail: number) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => {
              // setVideoUrl(s3Url + thumbnail);
              if (previewVideo.current) {
                previewVideo.current.src = s3Url + thumbnail;
              }
              setIsPreviewVideoModalOpen(true);
            }}
          >
            Preview
          </Button>
        );
      },
    },
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      render: (thumbnail: number) => {
        return <Image src={s3Url + thumbnail} width={50} />;
      },
    },
    {
      title: 'Total View',
      dataIndex: 'total_view',
      key: 'total_view',
    },
    {
      title: 'like',
      dataIndex: 'like',
      key: 'like',
    },
    {
      title: 'Click Buy Button',
      dataIndex: 'total_click_buy',
      key: 'total_click_buy',
    },
    {
      title: t('home-feed.status'),
      dataIndex: 'status',
      key: 'status',
      // onCell: (record: HomeFeedModel) => {
      //   return onCell(record, 'status', t('home-feed.status'));
      // },
      render: (status: number) => {
        if (status === 1) {
          return <span>{t('common.active')}</span>;
        } else if (status === 0) {
          return <span>{t('common.inactive')}</span>;
        }
      },
    },
    {
      title: t('common.updatedAt'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (date: string) => moment(date).format('LLL'),
      sorter: true,
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      key: 'actions',
      // fixed: 'right',
      width: 100,
      render: (text: string, record: ShortVideo) => {
        // const editable = isEditing(record);
        return (
          <>
            <Button
              type="default"
              size="small"
              onClick={() => {
                setUpdateShortVideoData({
                  id: record.id,
                  title: record.title,
                  group_id: record.group_id,
                  status: record.status,
                });
                setIsUpdateModalOpen(true);
              }}
            >
              {t('common.edit')}
            </Button>
            <Button
              type="default"
              size="small"
              onClick={() => {
                setUpdateVideoFileData({
                  id: record.id,
                  group_id: record.group_id,
                  video: record.video,
                });
                setIsUpdateVideoModalOpen(true);
              }}
            >
              edit video
            </Button>
            <Button
              type="default"
              size="small"
              onClick={() => {
                setUpdateThumbnailFileData({
                  id: record.id,
                  group_id: record.group_id,
                  thumbnail: record.thumbnail,
                });
                setIsUpdateThumbnailModalOpen(true);
              }}
            >
              edit thumbnail
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Button type="default" size="small" onClick={() => setIsCreateModalOpen(true)} style={{ marginBottom: 10 }}>
        Create Short Video
      </Button>
      <PageTitle>{t('common.firebase')}</PageTitle>
      <Table
        // components={{
        //   body: {
        //     cell: EditableCell,
        //   },
        // }}
        // rowClassName="editable-row"
        tableLayout="auto"
        columns={columns}
        dataSource={tableData.data}
        pagination={tableData.pagination}
        loading={tableData.loading}
        onChange={handleTableChange}
        // scroll={{ x: 800 }}
        bordered
      />
      <Modal
        title={'Create Short Video'}
        centered
        open={isCreateModalOpen}
        onOk={() => handleCreateShortVideo()}
        onCancel={() => {
          setLoading(false);
          setIsCreateModalOpen(false);
        }}
        confirmLoading={loading}
      >
        <Spin spinning={loading} tip={spinTip}>
          <Form name="basic" form={form}>
            <Form.Item
              label={t('common.title')}
              name="title"
              rules={[{ required: true, message: 'Please input title!' }]}
            >
              <Input
                placeholder={t('common.title')}
                defaultValue={createShortVideoData.title}
                onChange={(e) => setCreateShortVideoData({ ...createShortVideoData, title: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label={'Group Id'}
              name="group_id"
              rules={[{ required: true, message: 'Please input Group Id!' }]}
            >
              <Input
                placeholder={'Group Id'}
                defaultValue={createShortVideoData.group_id}
                type="number"
                onChange={(e) =>
                  setCreateShortVideoData({ ...createShortVideoData, group_id: parseInt(e.target.value) })
                }
              />
            </Form.Item>
            <Form.Item label={'Video'} name="video-file" rules={[{ required: true }]}>
              <Row gutter={8}>
                <Col span={16}>
                  <Form.Item name="video-file" noStyle>
                    <Input
                      placeholder={'Video File'}
                      accept="video/mp4,video/x-m4v,video/*"
                      type="File"
                      onChange={(e) => onChangeInputVideoFile(e)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Button
                    size="small"
                    disabled={videoUrl.length > 0 ? false : true}
                    onClick={() => setIsPreviewVideoModalOpen(true)}
                  >
                    Preview
                  </Button>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label={'Thumbnail'} name="thumbnail-file" rules={[{ required: true }]}>
              <Row gutter={8}>
                <Col span={16}>
                  <Form.Item name="thumbnail-file" noStyle>
                    <Input
                      placeholder={'Thumbnail File'}
                      accept="image/*"
                      type="File"
                      onChange={(e) => onChangeInputImageFile(e)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Image
                    height={50}
                    src={thumbnailUrl}
                    style={{ display: thumbnailUrl.length > 0 ? 'inline' : 'none' }}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      <Modal
        title={'Update Short Video'}
        centered
        open={isUpdateModalOpen}
        onOk={() => handleUpdateShortVideo()}
        onCancel={() => {
          setLoading(false);
          setIsUpdateModalOpen(false);
        }}
        confirmLoading={loading}
      >
        <Form name="basic" form={updateForm}>
          <Form.Item
            label={t('common.title')}
            name="title"
            rules={[{ required: true, message: 'Please input title!' }]}
          >
            <Input
              placeholder={t('common.title')}
              defaultValue={updateShortVideoData.title}
              onChange={(e) => setUpdateShortVideoData({ ...updateShortVideoData, title: e.target.value })}
            />
          </Form.Item>
          <Form.Item label={'Group Id'} name="group_id" rules={[{ required: true, message: 'Please input Group Id!' }]}>
            <Input
              placeholder={'Group Id'}
              defaultValue={updateShortVideoData.group_id}
              type="number"
              onChange={(e) => setUpdateShortVideoData({ ...updateShortVideoData, group_id: parseInt(e.target.value) })}
            />
          </Form.Item>{' '}
          <Form.Item
            label={t('home-feed.status')}
            name="status"
            rules={[{ required: true, message: 'Please select status!' }]}
          >
            <Select
              defaultValue={updateShortVideoData.status}
              style={{ width: 120 }}
              onChange={(value: number) => setUpdateShortVideoData({ ...updateShortVideoData, status: value })}
              options={[
                { value: 0, label: t('common.inactive') },
                { value: 1, label: t('common.active') },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={'Update Video'}
        centered
        open={isUpdateVideoModalOpen}
        onOk={() => handleUpdateVideoFile()}
        onCancel={() => {
          setLoading(false);
          setIsUpdateVideoModalOpen(false);
        }}
        confirmLoading={loading}
      >
        <Spin spinning={loading} tip={spinTip}>
          <Form name="basic">
            <Form.Item label={'Video'} name="video-file" rules={[{ required: true }]}>
              <Row gutter={8}>
                <Col span={16}>
                  <Form.Item name="video-file" noStyle>
                    <Input
                      placeholder={'Video File'}
                      accept="video/mp4,video/x-m4v,video/*"
                      type="File"
                      onChange={(e) => onChangeInputVideoFile(e)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Button
                    size="small"
                    disabled={videoUrl.length > 0 ? false : true}
                    onClick={() => setIsPreviewVideoModalOpen(true)}
                  >
                    Preview
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      <Modal
        title={'Update Thumbnail'}
        centered
        open={isUpdateThumbnailModalOpen}
        onOk={() => handleUpdateThumbnailFile()}
        onCancel={() => {
          setLoading(false);
          setIsUpdateThumbnailModalOpen(false);
        }}
        confirmLoading={loading}
      >
        <Spin spinning={loading} tip={spinTip}>
          <Form name="basic">
            <Form.Item label={'Thumbnail'} name="thumbnail-file" rules={[{ required: true }]}>
              <Row gutter={8}>
                <Col span={16}>
                  <Form.Item name="thumbnail-file" noStyle>
                    <Input
                      placeholder={'Thumbnail File'}
                      accept="image/*"
                      type="File"
                      onChange={(e) => onChangeInputImageFile(e)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Image
                    height={50}
                    src={thumbnailUrl}
                    style={{ display: thumbnailUrl.length > 0 ? 'inline' : 'none' }}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      <Modal
        title={'Preview Video'}
        centered
        zIndex={1001}
        open={isPreviewVideoModalOpen}
        onOk={() => setIsPreviewVideoModalOpen(false)}
        onCancel={() => {
          if (previewVideo.current) {
            previewVideo.current.pause();
          }
          setIsPreviewVideoModalOpen(false);
        }}
        confirmLoading={loading}
      >
        <video ref={previewVideo} controls style={{ width: '100%' }} />
      </Modal>
    </>
  );
};

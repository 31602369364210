import React from 'react';
import { useTranslation } from 'react-i18next';
import { Seller } from '../../components/seller/Seller';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const SellerPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>Seller</PageTitle>
      <Seller />
    </>
  );
};

export default SellerPage;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  TablePaginationConfig,
  Button,
  Input,
  Space,
  InputRef,
  Form,
  Popconfirm,
  InputNumber,
  Select,
  Table,
  TableColumnsType,
} from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import type { ColumnType } from 'antd/es/table';
import type { FilterConfirmProps, FilterValue, SorterResult } from 'antd/es/table/interface';
import { PageOptionsModel, PageOrder } from '@app/domain/PageMetaModel';
// import { Table } from 'components/common/Table/Table';
import { useTranslation } from 'react-i18next';
import { AdministratorModel } from '@app/domain/AdministratorModel';
import { useMounted } from '@app/hooks/useMounted';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import { Modal } from '../common/Modal/Modal';
import { notificationController } from '@app/controllers/notificationController';
import { getErrorMessage } from '@app/utils/utils';
import { HomeFeedModel } from '../../domain/HomeFeedModel';
import { getHomeFeed, updateHomeFeed, updateHomeFeedCache, updateHomeFeedRequest } from '../../api/homeFeed.api';
import { PageTitle } from '../common/PageTitle/PageTitle';
import TextArea from 'antd/lib/input/TextArea';

const initialPagination: PageOptionsModel = {
  current: 1,
  pageSize: 20,
};

const initialUpdateHomeFeed: updateHomeFeedRequest = {
  id: 0,
  title: '',
  description: null,
  lang: 0,
  type: 0,
  order: 0,
  status: 0,
  content: '',
  setting: null,
};
export const HomeFeed: React.FC = () => {
  const [tableData, setTableData] = useState<{
    data: HomeFeedModel[];
    pagination: PageOptionsModel;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: true,
  });
  const [updateHomeFeedData, setUpdateHomeFeedData] = useState<updateHomeFeedRequest>(initialUpdateHomeFeed);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [editingKey, setEditingKey] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [isUpdateCacheModalOpen, setIsUpdateCacheModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const fetch = useCallback(
    (pagination: PageOptionsModel) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getHomeFeed({ params: pagination })
        .then((res) => {
          if (isMounted.current) {
            const pagination = {
              ...res.meta,
              showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
              showQuickJumper: true,
            };
            // setTableData({ data: res.data, pagination: res.meta, loading: false });
            setTableData({ data: res.data, pagination: pagination, loading: false });
          }
        })
        .finally(() => {
          return setTableData((tableData) => ({ ...tableData, loading: false }));
        });
    },
    [isMounted],
  );
  useEffect(() => {
    getHomeFeed({ params: tableData.pagination })
      .then((res) => {
        const pagination = {
          ...res.meta,
          showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
          showQuickJumper: true,
        };
        setTableData({ data: res.data, pagination: pagination, loading: false });
      })
      .finally(() => {
        return setTableData((tableData) => ({ ...tableData, loading: false }));
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableChange = (
    tablePagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<HomeFeedModel> | SorterResult<HomeFeedModel>[],
  ) => {
    const pagination: PageOptionsModel = {
      current: tablePagination.current,
      pageSize: tablePagination.pageSize,
    };
    if ('order' in sorter && sorter.order == 'ascend') {
      pagination.sortColumn = sorter.field?.toString();
      pagination.sortOrder = PageOrder.ASC;
    } else if ('order' in sorter && sorter.order == 'descend') {
      pagination.sortColumn = sorter.field?.toString();
      pagination.sortOrder = PageOrder.DESC;
    }
    if (searchText.length > 0) {
      pagination.filterValue = searchText;
      pagination.filterColumn = searchedColumn;
    }
    if (
      tablePagination.current != tableData.pagination.current ||
      tablePagination.pageSize != tableData.pagination.pageSize ||
      Object.keys(sorter).length > 0
    ) {
      fetch(pagination);
    }
    cancel();
  };
  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    const pagination: PageOptionsModel = {
      current: tableData.pagination.current,
      pageSize: tableData.pagination.pageSize,
      filterValue: selectedKeys[0],
      filterColumn: dataIndex,
    };
    fetch(pagination);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string): ColumnType<AdministratorModel> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block', fontSize: 14, padding: 8 }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, fontSize: 14 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90, fontSize: 14 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const isEditing = (record: HomeFeedModel) => record.id === editingKey;

  const edit = (record: Partial<HomeFeedModel> & { id: React.Key }) => {
    form.setFieldsValue({
      title: record.title,
      description: record.description,
      lang: record.lang,
      type: record.type,
      order: record.order,
      status: record.status,
      content: record.content,
      setting: record.setting,
      created_at: moment(record.created_at).format('YYYY-MM-DD HH:mm:ss'),
      updated_at: moment(record.created_at).format('YYYY-MM-DD HH:mm:ss'),
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey(0);
  };

  const save = async (record: HomeFeedModel) => {
    try {
      const row = (await form.validateFields()) as HomeFeedModel;
      console.debug('row', row);
      console.debug('key', record.id);
      const updateData: updateHomeFeedRequest = {
        id: record.id,
        title: row.title,
        description: row.description,
        lang: row.lang,
        type: row.type,
        order: row.order,
        status: row.status,
        content: row.content,
        setting: row.setting,
        created_at: row.created_at,
        updated_at: row.updated_at,
      };
      //   console.debug('updateData', updateData);
      const res = await updateHomeFeed(updateData);
      //   console.debug('res', res);
      const newData = [...tableData.data];

      const index = newData.findIndex((item) => record.id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
      } else {
        newData.push(row);
      }
      setTableData({ ...tableData, data: newData });
      notificationController.success({
        message: `${t('common.edit')}: ${row.title}`,
      });
      setEditingKey(0);
    } catch (errInfo) {
      notificationController.error({
        message: `${t('common.edit')}: ${record.title}`,
        description: getErrorMessage(errInfo),
      });
      console.error('Validate Failed:', errInfo);
    }
  };
  const handleUpdateModal = (record: HomeFeedModel) => {
    setUpdateHomeFeedData(record);
    let swipeDirection = 'Horizontal';
    if (record.setting) {
      const data = JSON.parse(record.setting);
      if (data.hasOwnProperty('swipe_direction')) {
        if (data.swipe_direction === 2) {
          swipeDirection = 'Vertical';
        }
      }
    }
    form.setFieldsValue({
      title: record.title,
      description: record.description,
      lang: record.lang,
      type: record.type,
      order: record.order,
      status: record.status,
      content: record.content,
      swipeDirection: swipeDirection,
    });
    setIsUpdateModalOpen(true);
  };

  const handleUpdateHomeFeed = async () => {
    setLoading(true);
    try {
      const res = await updateHomeFeed(updateHomeFeedData);
      notificationController.success({
        message: `${t('common.update')}: ${updateHomeFeedData.title}`,
      });
      const newData = [...tableData.data];
      const index = newData.findIndex((item) => updateHomeFeedData.id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...res,
        });
      }
      setTableData({ ...tableData, data: newData });
      setUpdateHomeFeedData(initialUpdateHomeFeed);
      // setIsUpdateModalOpen(false);
    } catch (errInfo) {
      notificationController.error({
        message: `${t('common.update')}: ${updateHomeFeedData.title}`,
        description: getErrorMessage(errInfo),
      });
    } finally {
      setLoading(false);
      setIsUpdateModalOpen(false);
    }
  };
  const handleUpdateHomeFeedCache = async () => {
    setLoading(true);
    try {
      await updateHomeFeedCache();
      notificationController.success({
        message: `${t('common.update')}: ${updateHomeFeedData.title}`,
      });
    } catch (errInfo) {
      notificationController.error({
        message: `${t('common.update')}: ${updateHomeFeedData.title}`,
        description: getErrorMessage(errInfo),
      });
    } finally {
      setLoading(false);
      setIsUpdateCacheModalOpen(false);
    }
  };
  const onCell = (record: HomeFeedModel, dataIndex: string, title: string, required = true, inputType = 'text') => ({
    record,
    // inputType: col.dataIndex === 'phone' ? 'number' : 'text',
    inputType: inputType,
    dataIndex: dataIndex,
    title: title,
    editing: isEditing(record),
    required: required,
  });

  const columns: TableColumnsType<HomeFeedModel> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: t('common.title'),
      dataIndex: 'title',
      key: 'title',
      // onCell: (record: HomeFeedModel) => {
      //   return onCell(record, 'title', t('common.title'));
      // },
      // ellipsis: true,
      // textWrap: 'word-break',
      // ...getColumnSearchProps('title'),
    },
    {
      title: t('home-feed.description'),
      dataIndex: 'description',
      key: 'description',
      // ellipsis: true,
      // textWrap: 'word-break',
      // onCell: (record: HomeFeedModel) => {
      //   return onCell(record, 'description', t('home-feed.description'));
      // },
    },
    {
      title: t('home-feed.content'),
      dataIndex: 'content',
      key: 'content',
      // ellipsis: true,
      // width: 200,
      // textWrap: 'word-break',
      // onCell: (record: HomeFeedModel) => {
      //   return onCell(record, 'content', t('home-feed.content'), true, 'number');
      // },
      render: (content: string) => {
        return (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', textOverflow: 'ellipsis' }}>{content}</div>
        );
      },
    },
    {
      title: t('home-feed.type'),
      dataIndex: 'type',
      key: 'type',
      // onCell: (record: HomeFeedModel) => {
      //   return onCell(record, 'type', t('home-feed.type'), true, 'number');
      // },
      sorter: true,
      render: (type: number) => {
        if (type === 1) {
          return <span>{'Banner'}</span>;
        } else if (type === 2) {
          return <span>{'Shop'}</span>;
        } else if (type === 3) {
          return <span>{'Feature Store'}</span>;
        } else if (type === 4) {
          return <span>{'Category'}</span>;
        } else if (type === 5) {
          return <span>{'Seller Feature Store'}</span>;
        } else if (type === 6) {
          return <span>{'Short Videos'}</span>;
        }
      },
    },
    {
      title: t('home-feed.lang'),
      dataIndex: 'lang',
      key: 'lang',
      onCell: (record: HomeFeedModel) => {
        return onCell(record, 'lang', t('home-feed.lang'), true, 'number');
      },
      sorter: true,
      render: (lang: number) => {
        if (lang === 1) {
          return <span>{t('common.en')}</span>;
        } else if (lang === 2) {
          return <span>{t('common.zh')}</span>;
        }
      },
    },
    {
      title: t('home-feed.order'),
      dataIndex: 'order',
      key: 'order',
      sorter: true,
      // onCell: (record: HomeFeedModel) => {
      //   return onCell(record, 'order', t('home-feed.order'), true, 'number');
      // },
    },
    {
      title: t('home-feed.swipeDirection'),
      dataIndex: 'setting',
      key: 'setting',
      // onCell: (record: HomeFeedModel) => {
      //   return onCell(record, 'setting', t('home-feed.setting'), false);
      // },
      render: (setting: string) => {
        const data = JSON.parse(setting);
        if (!data) {
          return <span>{setting}</span>;
        }
        let direction = t('home-feed.swipeDirectionVertical');
        if (data.hasOwnProperty('swipe_direction')) {
          if (data.swipe_direction === 1) {
            direction = t('home-feed.swipeDirectionHorizontal');
          }
        }
        return <span>{direction}</span>;
      },
    },
    {
      title: t('home-feed.status'),
      dataIndex: 'status',
      key: 'status',
      // onCell: (record: HomeFeedModel) => {
      //   return onCell(record, 'status', t('home-feed.status'));
      // },
      render: (status: number) => {
        if (status === 1) {
          return <span>{t('common.active')}</span>;
        } else if (status === 0) {
          return <span>{t('common.inactive')}</span>;
        }
      },
    },
    {
      title: t('common.updatedAt'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (date: string) => moment(date).format('LLL'),
      sorter: true,
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 100,
      render: (text: string, record: HomeFeedModel) => {
        const editable = isEditing(record);
        return (
          <Space>
            {editable ? (
              <>
                <Button type="primary" size="small" onClick={() => save(record)}>
                  {t('common.save')}
                </Button>
                <Popconfirm title={t('tables.cancelInfo')} onConfirm={cancel}>
                  <Button type="ghost" size="small">
                    {t('common.cancel')}
                  </Button>
                </Popconfirm>
              </>
            ) : (
              <>
                {/* <Button type="ghost" size="small" disabled={editingKey !== 0} onClick={() => edit(record)}>
                  {t('common.edit')}
                </Button> */}
                <Button type="default" size="small" onClick={() => handleUpdateModal(record)}>
                  {t('common.edit')}
                </Button>
              </>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Button type="default" size="small" onClick={() => setIsUpdateCacheModalOpen(true)} style={{ marginBottom: 10 }}>
        Update Cache
      </Button>
      <PageTitle>Home Feed</PageTitle>
      <Form form={form} component={false}>
        {/* <SearchInput placeholder={t('inputs.searchText')} allowClear /> */}
        <Table
          // components={{
          //   body: {
          //     cell: EditableCell,
          //   },
          // }}
          // rowClassName="editable-row"
          tableLayout="auto"
          columns={columns}
          dataSource={tableData.data}
          pagination={tableData.pagination}
          loading={tableData.loading}
          onChange={handleTableChange}
          // scroll={{ x: 800 }}
          bordered
        />
      </Form>

      <Modal
        title={'Confirm update home feed cache ?'}
        centered
        open={isUpdateCacheModalOpen}
        onOk={() => handleUpdateHomeFeedCache()}
        onCancel={() => {
          setIsUpdateCacheModalOpen(false);
        }}
        size="small"
        confirmLoading={loading}
      ></Modal>

      <Modal
        title={'Update Home Feed'}
        centered
        open={isUpdateModalOpen}
        onOk={() => handleUpdateHomeFeed()}
        onCancel={() => {
          setUpdateHomeFeedData(initialUpdateHomeFeed);
          setIsUpdateModalOpen(false);
        }}
        size="large"
        confirmLoading={loading}
      >
        <Form name="basic" form={form}>
          <Form.Item
            label={t('common.title')}
            name="title"
            rules={[{ required: true, message: 'Please input home feed title!' }]}
          >
            <Input
              placeholder={t('common.title')}
              defaultValue={updateHomeFeedData.title}
              onChange={(e) => setUpdateHomeFeedData({ ...updateHomeFeedData, title: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label={t('home-feed.description')}
            name="description"
            rules={[{ required: true, message: 'Please input home feed description!' }]}
          >
            <Input
              placeholder={t('home-feed.description')}
              value={updateHomeFeedData.description ? updateHomeFeedData.description : ''}
              // onChange={(e) => setUpdateHomeFeedData({ ...updateHomeFeedData, description: e.target.value })}
            />
          </Form.Item>

          <Form.Item
            label={t('home-feed.content')}
            name="content"
            rules={[{ required: true, message: 'Please input home feed content!' }]}
          >
            <TextArea
              rows={4}
              defaultValue={updateHomeFeedData.content}
              onChange={(e) => setUpdateHomeFeedData({ ...updateHomeFeedData, content: e.target.value })}
            />
          </Form.Item>

          <Form.Item
            label={t('home-feed.type')}
            name="type"
            rules={[{ required: true, message: 'Please input your type!' }]}
          >
            <Select
              defaultValue={updateHomeFeedData.type}
              style={{ width: 250 }}
              options={[
                { value: 2, label: 'Shop' },
                { value: 3, label: 'Feature Store' },
                { value: 5, label: 'Seller Feature Store' },
                { value: 6, label: 'Short Videos' },
              ]}
              onChange={(value: number) => setUpdateHomeFeedData({ ...updateHomeFeedData, type: value })}
            />
          </Form.Item>

          <Form.Item
            label={t('home-feed.lang')}
            name="lang"
            rules={[{ required: true, message: 'Please input your language!' }]}
          >
            <Select
              defaultValue={updateHomeFeedData.lang}
              style={{ width: 120 }}
              options={[
                { value: 1, label: t('common.en') },
                { value: 2, label: t('common.zh') },
              ]}
              onChange={(value: number) => setUpdateHomeFeedData({ ...updateHomeFeedData, lang: value })}
            />
          </Form.Item>

          <Form.Item
            label={t('home-feed.order')}
            name="order"
            rules={[{ required: true, message: 'Please input your order!' }]}
          >
            <InputNumber
              placeholder={t('home-feed.order')}
              defaultValue={updateHomeFeedData.order}
              onChange={(value: number | null) => {
                if (value) {
                  setUpdateHomeFeedData({ ...updateHomeFeedData, order: value });
                }
              }}
            />
          </Form.Item>

          <Form.Item
            label={t('home-feed.swipeDirection')}
            name="swipeDirection"
            rules={[{ required: true, message: 'Please input your swipe direction!' }]}
          >
            <Select
              defaultValue="Horizontal"
              style={{ width: 120 }}
              options={[
                { value: 'Horizontal', label: t('home-feed.swipeDirectionHorizontal') },
                { value: 'Vertical', label: t('home-feed.swipeDirectionVertical') },
              ]}
              onChange={(value: string) => {
                if (value === 'Horizontal') {
                  setUpdateHomeFeedData({ ...updateHomeFeedData, setting: JSON.stringify({ swipe_direction: 1 }) });
                } else {
                  setUpdateHomeFeedData({ ...updateHomeFeedData, setting: JSON.stringify({ swipe_direction: 2 }) });
                }
              }}
            />
          </Form.Item>

          <Form.Item
            label={t('home-feed.status')}
            name="status"
            rules={[{ required: true, message: 'Please input your swipe direction!' }]}
          >
            <Select
              defaultValue={updateHomeFeedData.status}
              style={{ width: 120 }}
              onChange={(value: number) => setUpdateHomeFeedData({ ...updateHomeFeedData, status: value })}
              options={[
                { value: 0, label: t('common.inactive') },
                { value: 1, label: t('common.active') },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

import { httpApi } from '@app/api/http.api';
import { PageMetaModel, PageOptionsModel } from '../domain/PageMetaModel';

export const IDENTITY_STATUS = {
  IDENTITY_UNVERIFIED: 0,
  IDENTITY_VERIFIED: 1,
  SUSPECTED_SELLER: 2,
  NO_IDENTITY_VERIFIED_NO_STRIPE_VERIFIED: 3,
  NO_IDENTITY_VERIFIED_BUT_STRIPE_VERIFIED: 10,
  IDENTITY_VERIFIED_AND_STRIPE_VERIFIED: 11,
  NO_IDENTITY_VERIFIED_NO_STRIPE_VERIFIED_TEMP_ALLOW_CREDIT_CARD: 4,
};

export interface GetSellersRequest {
  params: PageOptionsModel;
}

export interface EnableSettingRequest {
  enable: boolean;
}

export interface SellerResponse {
  id: string;
  email: string | null;
  phone: string | null;
  display_name: string | null;
  unique_url: string | null;
  identity_status: number | null;
  commission_rate: number;
  category: string | null;
  transaction_count: number;
  created_at: Date;
  updated_at: Date;
}

export interface CommissionRateRequest {
  rate: number;
}

export interface SellersResponse {
  meta: PageMetaModel;
  data: SellerResponse[];
}

export const getSellers = (pagePayload: GetSellersRequest): Promise<SellersResponse> =>
  httpApi.get<SellersResponse>('v1/admin/seller', { ...pagePayload }).then(({ data }) => {
    // console.log(data);
    return data as SellersResponse;
  });
export const enableCreditCart = (SellerId: string, Payload: EnableSettingRequest): Promise<void> =>
  httpApi.put(`v1/admin/seller/${SellerId}/enable-credit-card`, { ...Payload }).then(({ data }) => {
    console.log(data);
  });

export const upsertCommissionRate = (SellerId: string, Payload: CommissionRateRequest): Promise<void> =>
  httpApi.put(`v1/admin/seller/${SellerId}/commission-setting`, { ...Payload }).then(({ data }) => {
    console.log(data);
  });

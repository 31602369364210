import { httpApi } from '@app/api/http.api';
import { PageMetaModel, PageOptionsModel } from '../domain/PageMetaModel';

export interface GetGroupOrdersRequest {
  params: PageOptionsModel;
}

export interface GroupOrderItem {
  type: 'item';
  id: number;
  price?: number | null;
  hide?: boolean;
}

export interface GroupOrderCarousel {
  type: 'carousel';
  images: string[] | null;
  hide: boolean;
}

export interface GroupOrderBanner {
  type: 'banner';
  title: string;
  description: string;
  image: string;
  hide: boolean;
}
export interface GroupOrderActionCard {
  type: 'action_card';
  title: string;
  description: string;
  buttonText?: string;
  buttonLink?: string;
  image: string;
  hide: boolean;
}

export interface GroupOrderSubscribe {
  type: 'subscribe';
  title: string;
  description: string;
  hide: boolean;
}

export interface GroupOrderBlog {
  type: 'blog';
  image: string;
  title: string;
  description: string;
  date: string;
  link: string;
  hide: boolean;
}

export interface GroupOrderFaq {
  question: string;
  answer: string;
  hide: boolean;
}

export interface GroupOrderFaqs {
  type: 'faq';

  // Note: transform to class if need to Expose
  content: GroupOrderFaq[];

  hide: boolean;
}

export interface GroupOrderVideo {
  type: 'video';
  link: string;
  hide: boolean;
}

export interface GroupOrderLinks {
  type: 'link';

  content: GroupOrderLink[];

  backgroundImage: string;

  hide: boolean;
}

export interface GroupOrderLink {
  title: string;
  url: string;
  icon: string;
  hide: boolean;
}

export interface GroupOrderText {
  type: 'text';
  text: string;
  hide: boolean;
}

export interface GroupOrderGiftCard {
  type: 'gift_card';
  id: number;
}

export interface GroupOrderCategory {
  type: 'category';
  name: string;
  hide: boolean;
}

export interface GroupOrderTarget {
  type: 'group';
  group_order_id: number;
}

export type GroupOrderContent =
  | GroupOrderItem
  | GroupOrderCarousel
  | GroupOrderBanner
  | GroupOrderText
  | GroupOrderCategory
  | GroupOrderTarget
  | GroupOrderLinks
  | GroupOrderVideo
  | GroupOrderGiftCard
  | GroupOrderActionCard
  | GroupOrderSubscribe
  | GroupOrderBlog
  | GroupOrderFaqs;

export interface GroupOrderDriverTipSetting {
  enableDriverTipV2: boolean | null;
  defaultDriverTip: number | null;
  defaultDriver2ndTip: number | null;
  defaultDriver3rdTip: number | null;
}

export interface GroupOrderPaymentInfo {
  venmo: string | null;
  venmoLast4: string | null;

  paypal: string | null;

  zelle: string | null;
  zelleRecipient: string | null;

  enableCash: boolean | null;

  enableCustomizedPayment: boolean | null;
  customizedTitle: string;
  customizedDescription: string;
}
export interface GroupOrderReferSetting {
  type: string;
  description: string;

  commissionPaymentMethod?: number;
  commissionType: number;
  retailPartialItems: boolean;
  noteToRetailer?: string;
  minOrderAmount?: number | null;
  orderPaymentRule?: number | null;
  orderDeposit?: number | null;
  hideCustomerInfo?: boolean | null;

  // s2e
  enableShare2Earn?: boolean;
  storeType?: number;
  // @Expose({ name: 'commission_type' }) commissionType: number
  commonCommissionRate?: number;
  // @Expose({ name: 'retail_partial_items' }) retailPartialItems: boolean
  deliveryInstructions?: string;
  refundInstructions?: string;
}

export interface ExtraFee {
  onlineExtraFee: number;
  cashExtraFee: number;
  checkExtraFee: number;
  wireExtraFee: number;
}

export interface S2ESetting {
  enabled: boolean;
  storeType: number;
  commissionType: number;
  commissionRateType?: number;
  commonCommissionRate?: number;
  retailPartialItems: boolean;
  deliveryInstructions?: string;
  refundInstructions?: string;
  paymentInstructions?: string;
  generalPassword?: string;
  publicResale: boolean;
}

export interface SocialMedia {
  email: string;
  instagram: string;
  twitter: string;
  facebook: string;
  linkedin: string;
}

export interface GroupOrderBlacklistDatesPeriod {
  from: string;
  to: string;
}

export interface GroupOrderBlacklistDateInfo {
  enabled: boolean;
  timezoneStr: string;
  period: GroupOrderBlacklistDatesPeriod[];
}

export interface GroupOrderCustomerSupportInfo {
  enabled: boolean;
  description: string;
  showAppLink: boolean;
}
export interface GroupOrderDisplaySetting {
  promoTags: string;
}

export interface GroupOrder {
  id: number;
  storeId: number | null;
  sellerId: string | null;
  deadline: Date | null;
  deliveryOptionId: number | null;
  deliveryDate: Date | null;
  type: number | null;
  valid: boolean;
  createdAt: Date;
  updatedAt: Date;

  shortId: string | null;
  enableTip: boolean | null;
  defaultTip: number | null; // v1

  driverTipSetting: GroupOrderDriverTipSetting[] | null; // v2

  content: GroupOrderContent[];

  enablePhoneLogin: boolean | null;
  enableWxLogin: boolean | null;
  pickupOptionId: number | null;
  pickupDate: Date | null;
  maxOrderCount: number | null;
  groupOrderSettingId: number | null;
  customerId: string | null;
  status: number;
  creator: string | null;
  unified: boolean | null;
  name: string | null;
  description: string | null;
  bannerImage: string | null;
  groupupVisible: number;
  visible: boolean | null;

  paymentSetting: number | null;

  paymentInfo?: GroupOrderPaymentInfo;
  // todo use paymentInfo
  paymentInstruction: string | null;
  venmo: string | null; // todo deprecate
  paypal: string | null; // todo deprecate
  zelle: string | null; // todo deprecate
  venmoLast4: string | null; // todo deprecate
  zelleRecipient: string | null; // todo deprecate
  cashapp: string | null; // todo deprecate
  enableCash: boolean | null; // todo deprecate

  contactEmail: string | null;
  contactPhone: string | null;
  contactWx: string | null;
  deliveryOptionIds: number[] | null;
  fromSite: number | null;
  isPublic: boolean | null;
  showTransactions: boolean | null;
  m1CustomerFee: boolean | null;
  asStorage: boolean | null;
  manageToken: string | null;
  showSoldOut: boolean | null;
  showGroup: boolean | null;

  enableProductRecommendation: boolean | null;
  theme: number | null;

  enableFeaturedHome: boolean | null;

  storeCategory: number | null;

  groupOrderReferSetting: GroupOrderReferSetting[] | null;

  locationLat: number | null;
  locationLon: number | null;

  extraFees: ExtraFee[];
  productDisplay: number | null;
  publicStatus: number | null;

  serviceZipcodes: string[] | null;
  workspaceId: number | null;
  zipcode: string | null;
  vendorGroupId: number | null;

  s2eSetting: S2ESetting[] | null;

  enableAirmartTip: boolean;

  s2ePublicStatus: number | null;

  socialMedia: SocialMedia[] | null;

  blacklistDatesInfo: GroupOrderBlacklistDateInfo | undefined;

  reviewDisplay: number | null;
  aiCategory: number | null;

  customerSupportInfo: GroupOrderCustomerSupportInfo | undefined;

  displaySetting: GroupOrderDisplaySetting | null;
}

export interface GroupOrderCalculatedResponse {
  views: number;
  purchases: number;
}

export interface GroupOrderFeatureSettingResponse {
  enable_message: boolean;
  enable_item_fulfill_setting: boolean;
  enable_rating_review: boolean;
  zh_whitelist: boolean;
  en_whitelist: boolean;
}
export interface GroupOrderCommissionSetting {
  commission_rate: number;
}
export interface GroupResponse {
  group_order: GroupOrder;
  calculated: GroupOrderCalculatedResponse;
  feature_setting: GroupOrderFeatureSettingResponse;
  commission_setting: GroupOrderCommissionSetting;
}

export interface GetGroupOrderResponse {
  meta: PageMetaModel;
  data: GroupResponse[];
}

export interface updateGroupOrderDisplaySettingRequest {
  id: number;
  displaySetting: GroupOrderDisplaySetting;
}

export interface featureSettingRequest {
  enable: boolean;
}

export interface featureSettingWhitelistRequest {
  enable: boolean;
  lang: string;
}

export interface CommissionRateRequest {
  rate: number;
  group_id: number;
}

export const getGroupOrders = (pagePayload: GetGroupOrdersRequest): Promise<GetGroupOrderResponse> =>
  httpApi.get<GetGroupOrderResponse>('v1/admin/group', { ...pagePayload }).then(({ data }) => {
    console.log(data);
    return data as GetGroupOrderResponse;
  });

export const updateGroupOrderDisplaySetting = (Payload: updateGroupOrderDisplaySettingRequest): Promise<void> =>
  httpApi
    .put(`v1/admin/group/${Payload.id}/display-setting`, { promo_tags: Payload.displaySetting.promoTags })
    .then(({ data }) => {
      console.log(data);
    });

export const updateFeatureSettingEnableMessage = (GroupId: number, Payload: featureSettingRequest): Promise<void> =>
  httpApi.put(`v1/admin/group/${GroupId}/feature-setting/enable-message`, { ...Payload }).then(({ data }) => {
    console.log(data);
  });

export const updateFeatureSettingEnableItemFulfillSetting = (
  GroupId: number,
  Payload: featureSettingRequest,
): Promise<void> =>
  httpApi.put(`v1/admin/group/${GroupId}/feature-setting/enable-item-fulfill`, { ...Payload }).then(({ data }) => {
    console.log(data);
  });

export const updateFeatureSettingEnableRatingReview = (
  GroupId: number,
  Payload: featureSettingRequest,
): Promise<void> =>
  httpApi.put(`v1/admin/group/${GroupId}/feature-setting/enable-rating-review`, { ...Payload }).then(({ data }) => {
    console.log(data);
  });

export const updateFeatureSettingSearchWhitelist = (
  GroupId: number,
  Payload: featureSettingWhitelistRequest,
): Promise<void> =>
  httpApi.put(`v1/admin/group/${GroupId}/feature-setting/set-whitelist`, { ...Payload }).then(({ data }) => {
    console.log(data);
  });

export const upsertCommissionRate = (SellerId: string, Payload: CommissionRateRequest): Promise<void> =>
  httpApi.put(`v1/admin/group/${SellerId}/commission-setting`, { ...Payload }).then(({ data }) => {
    console.log(data);
  });

import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Input,
  Modal,
  Form,
  Spin,
  TableColumnsType,
  Table,
  Select,
  TablePaginationConfig,
  Tooltip,
} from 'antd';

import { PageOptionsModel, PageOrder } from '@app/domain/PageMetaModel';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { notificationController } from '@app/controllers/notificationController';
import { getErrorMessage } from '@app/utils/utils';
import { PageTitle } from '../common/PageTitle/PageTitle';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import moment from 'moment';
import {
  CommissionRateRequest,
  GroupResponse,
  featureSettingRequest,
  getGroupOrders,
  updateFeatureSettingEnableItemFulfillSetting,
  updateFeatureSettingEnableMessage,
  updateFeatureSettingEnableRatingReview,
  updateFeatureSettingSearchWhitelist,
  updateGroupOrderDisplaySetting,
  updateGroupOrderDisplaySettingRequest,
  upsertCommissionRate,
} from '../../api/group.api';
import Search from 'antd/lib/input/Search';

const initialPagination: PageOptionsModel = {
  current: 1,
  pageSize: 10,
};
const initialGroupOrderDisplaySetting: updateGroupOrderDisplaySettingRequest = {
  id: 0,
  displaySetting: {
    promoTags: '',
  },
};
const initialFeatureSetting: featureSettingRequest = {
  enable: false,
};
const shopUrl = process.env.REACT_APP_SHOP_URL;
const featureSettingType = {
  enableMessage: 'enable_message',
  enableItemFulfillSetting: 'enable_item_fulfill_setting',
  enableRatingReview: 'enable_rating_review',
  enableZhWhitelist: 'zh_whitelist',
  enableEnWhitelist: 'en_whitelist',
};
const initialCommissionRateRequest: CommissionRateRequest = {
  rate: 0,
  group_id: 0,
};
export const Group: React.FC = () => {
  const [tableData, setTableData] = useState<{
    data: GroupResponse[];
    pagination: PageOptionsModel;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: true,
  });
  const [isUpdatePromoTagsModalOpen, setIsUpdatePromoTagsModalOpen] = useState<boolean>(false);
  const [updateDisplaySettingData, setUpdateDisplaySettingData] = useState<updateGroupOrderDisplaySettingRequest>(
    initialGroupOrderDisplaySetting,
  );
  const [featureSettingData, setFeatureSettingData] = useState<featureSettingRequest>(initialFeatureSetting);
  const [updateId, setUpdateId] = useState<number>(0);
  const [isFeatureSettingModalOpen, setIsFeatureSettingModalOpen] = useState<boolean>(false);
  const [updateFeatureSettingType, setUpdateFeatureSettingType] = useState<string>(featureSettingType.enableMessage);
  // commission rate
  const [isCommissionRateModalOpen, setIsCommissionRateModalOpen] = useState<boolean>(false);
  const [commissionRateData, setCommissionRateData] = useState<CommissionRateRequest>(initialCommissionRateRequest);
  const [updateSellerId, setUpdateSellerId] = useState<string>('');
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [formFeatureSetting] = Form.useForm();
  const [formCommission] = Form.useForm();

  const fetch = useCallback(
    (pagination: PageOptionsModel) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getGroupOrders({ params: pagination })
        .then((res) => {
          if (isMounted.current) {
            const newPagination: TablePaginationConfig & { search?: string } = {
              ...res.meta,
              showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
              showQuickJumper: true,
              // search: pagination.search,
            };
            if (pagination.search) {
              newPagination.search = pagination.search;
            }
            setTableData({ data: res.data, pagination: newPagination, loading: false });
          }
        })
        .finally(() => {
          return setTableData((tableData) => ({ ...tableData, loading: false }));
        });
    },
    [isMounted],
  );
  useEffect(() => {
    getGroupOrders({ params: tableData.pagination })
      .then((res) => {
        const pagination = {
          ...res.meta,
          showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
          showQuickJumper: true,
        };
        setTableData({ data: res.data, pagination: pagination, loading: false });
      })
      .finally(() => {
        return setTableData((tableData) => ({ ...tableData, loading: false }));
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.debug('tableData.pagination', tableData.pagination);
  // }, [tableData.pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableChange = (
    tablePagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<GroupResponse> | SorterResult<GroupResponse>[],
  ) => {
    const pagination: PageOptionsModel = {
      current: tablePagination.current,
      pageSize: tablePagination.pageSize,
    };

    if ('order' in sorter && sorter.order == 'ascend') {
      pagination.sortColumn = sorter.columnKey?.toString();
      pagination.sortOrder = PageOrder.ASC;
    } else if ('order' in sorter && sorter.order == 'descend') {
      pagination.sortColumn = sorter.columnKey?.toString();
      pagination.sortOrder = PageOrder.DESC;
    }
    if (tableData.pagination.search) {
      pagination.search = tableData.pagination.search;
    }
    if (
      tablePagination.current != tableData.pagination.current ||
      tablePagination.pageSize != tableData.pagination.pageSize ||
      Object.keys(sorter).length > 0
    ) {
      fetch(pagination);
    }
  };

  const handleUpdatePromoTags = async () => {
    setLoading(true);
    try {
      await updateGroupOrderDisplaySetting(updateDisplaySettingData);
      notificationController.success({
        message: 'Success',
        description: `Promo Tags Updated, Group ID: ${updateDisplaySettingData.id}`,
      });
      setIsUpdatePromoTagsModalOpen(false);
      fetch(tableData.pagination);
      setLoading(false);
    } catch (error) {
      notificationController.error({
        message: `Update Promo Tags: ${updateDisplaySettingData.id}`,
        description: getErrorMessage(error),
      });
      setLoading(false);
    }
    setUpdateDisplaySettingData(initialGroupOrderDisplaySetting);
  };

  const handleUpdateFeatureSetting = async () => {
    setLoading(true);
    try {
      if (updateFeatureSettingType === featureSettingType.enableMessage) {
        await updateFeatureSettingEnableMessage(updateId, featureSettingData);
      } else if (updateFeatureSettingType === featureSettingType.enableItemFulfillSetting) {
        await updateFeatureSettingEnableItemFulfillSetting(updateId, featureSettingData);
      } else if (updateFeatureSettingType === featureSettingType.enableRatingReview) {
        await updateFeatureSettingEnableRatingReview(updateId, featureSettingData);
      } else if (updateFeatureSettingType === featureSettingType.enableZhWhitelist) {
        await updateFeatureSettingSearchWhitelist(updateId, { ...featureSettingData, lang: 'zh' });
      } else if (updateFeatureSettingType === featureSettingType.enableEnWhitelist) {
        await updateFeatureSettingSearchWhitelist(updateId, { ...featureSettingData, lang: 'en' });
      } else {
        notificationController.error({
          message: `Update Feature Setting: ${updateId}`,
        });
      }
      notificationController.success({
        message: 'Success',
        description: `${featureSettingType.enableMessage} Updated, Group ID: ${updateId}`,
      });
      setIsFeatureSettingModalOpen(false);
      fetch(tableData.pagination);
      setLoading(false);
    } catch (error) {
      notificationController.error({
        message: `Update ${featureSettingType.enableMessage}: ${updateId}`,
        description: getErrorMessage(error),
      });
      setLoading(false);
    }
    setFeatureSettingData(initialFeatureSetting);
  };

  const handleCommissionRate = async () => {
    formCommission.validateFields().then(async () => {
      try {
        setLoading(true);
        await upsertCommissionRate(updateSellerId, commissionRateData);
        notificationController.success({
          message: 'Success',
          description: `Set commission rate to ${commissionRateData.rate}`,
        });
        setCommissionRateData(initialCommissionRateRequest);
        setUpdateSellerId('');
        fetch(tableData.pagination);
        setLoading(false);
      } catch (error) {
        notificationController.error({
          message: 'Fail',
          description: getErrorMessage(error),
        });
        setLoading(false);
      }
      setIsCommissionRateModalOpen(false);
    });
  };

  const onSearch = (value: string) => {
    const pagination: PageOptionsModel = {
      ...tableData.pagination,
      current: 1,
    };
    if (value === '') {
      delete pagination.search;
    } else {
      pagination.search = value;
    }
    setTableData((tableData) => ({ data: tableData.data, pagination: pagination, loading: true }));
    fetch(pagination);
  };

  const columns: TableColumnsType<GroupResponse> = [
    {
      title: 'ID',
      key: 'id',
      // sorter: true,
      render: (text, record) => record.group_order.id,
    },

    {
      title: 'Name',
      key: 'name',
      render: (text, record) => (
        <Tooltip title={record.group_order.name}>
          <div
            style={{
              width: 150, // 设置你需要的最大宽度
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <a href={shopUrl + '/c/s/' + record.group_order.id} target="_blank" rel="noreferrer">
              {record.group_order.name}
            </a>
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Views',
      key: 'views',
      render: (text, record) => record.calculated.views,
    },
    {
      title: 'Purchases',
      key: 'purchases',
      render: (text, record) => record.calculated.purchases,
    },
    {
      title: 'Promo Tags',
      key: 'promoTags',
      render: (text, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              const displaySetting =
                record.group_order.displaySetting ?? initialGroupOrderDisplaySetting.displaySetting;
              updateDisplaySettingData.displaySetting = displaySetting;
              updateDisplaySettingData.id = record.group_order.id;
              setUpdateDisplaySettingData(updateDisplaySettingData);
              form.setFieldsValue({ PromoTags: displaySetting.promoTags });
              setIsUpdatePromoTagsModalOpen(true);
            }}
          >
            {record.group_order.displaySetting?.promoTags ? record.group_order.displaySetting?.promoTags : 'Add'}
          </Button>
        );
      },
    },
    {
      title: 'Fulfillment',
      key: 'fulfillment',
      render: (text, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setUpdateId(record.group_order.id);
              setFeatureSettingData({ enable: record.feature_setting.enable_item_fulfill_setting });
              setUpdateFeatureSettingType(featureSettingType.enableItemFulfillSetting);
              setIsFeatureSettingModalOpen(true);
              formFeatureSetting.setFieldValue('setting', record.feature_setting.enable_item_fulfill_setting ? 1 : 0);
            }}
          >
            {record.feature_setting.enable_item_fulfill_setting ? 'Enabled' : 'Disabled'}
          </Button>
        );
      },
    },
    {
      title: 'Message',
      key: 'message',
      render: (text, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setUpdateId(record.group_order.id);
              setFeatureSettingData({ enable: record.feature_setting.enable_message });
              setUpdateFeatureSettingType(featureSettingType.enableMessage);
              setIsFeatureSettingModalOpen(true);
              formFeatureSetting.setFieldValue('setting', record.feature_setting.enable_message ? 1 : 0);
            }}
          >
            {record.feature_setting.enable_message ? 'Enabled' : 'Disabled'}
          </Button>
        );
      },
    },
    {
      title: (
        <Tooltip title="Rating Review">
          <span>Review</span>
        </Tooltip>
      ),
      key: 'review',
      render: (text, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setUpdateId(record.group_order.id);
              setFeatureSettingData({ enable: record.feature_setting.enable_rating_review });
              setUpdateFeatureSettingType(featureSettingType.enableRatingReview);
              setIsFeatureSettingModalOpen(true);
              formFeatureSetting.setFieldValue('setting', record.feature_setting.enable_rating_review ? 1 : 0);
            }}
          >
            {record.feature_setting.enable_rating_review ? 'Enabled' : 'Disabled'}
          </Button>
        );
      },
    },
    {
      title: (
        <Tooltip title="Search Whitelist">
          <span>Whitelist</span>
        </Tooltip>
      ),
      key: 'whitelist',
      render: (text, record) => {
        return (
          <>
            <Button
              type="link"
              size="small"
              style={{ paddingTop: 0, paddingBottom: 0 }}
              onClick={() => {
                setUpdateId(record.group_order.id);
                setFeatureSettingData({ enable: record.feature_setting.zh_whitelist });
                setUpdateFeatureSettingType(featureSettingType.enableZhWhitelist);
                setIsFeatureSettingModalOpen(true);
                formFeatureSetting.setFieldValue('setting', record.feature_setting.zh_whitelist ? 1 : 0);
              }}
            >
              {record.feature_setting.zh_whitelist ? 'ZH_Enabled' : 'ZH_Disabled'}
            </Button>
            <Button
              type="link"
              size="small"
              style={{ paddingTop: 0, paddingBottom: 0 }}
              onClick={() => {
                setUpdateId(record.group_order.id);
                setFeatureSettingData({ enable: record.feature_setting.en_whitelist });
                setUpdateFeatureSettingType(featureSettingType.enableEnWhitelist);
                setIsFeatureSettingModalOpen(true);
                formFeatureSetting.setFieldValue('setting', record.feature_setting.en_whitelist ? 1 : 0);
              }}
            >
              {record.feature_setting.en_whitelist ? 'EN_Enabled' : 'EN_Disabled'}
            </Button>
          </>
        );
      },
    },
    {
      title: (
        <Tooltip title="Commission Rate">
          <span>Commission</span>
        </Tooltip>
      ),
      key: 'commission',
      render: (text, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              if (!record.group_order.sellerId) {
                notificationController.error({
                  message: 'Error',
                  description: 'Seller ID not found!',
                });
                return;
              }
              setUpdateSellerId(record.group_order.sellerId);
              setIsCommissionRateModalOpen(true);
              setCommissionRateData({
                rate: record.commission_setting.commission_rate,
                group_id: record.group_order.id,
              });
              formCommission.setFieldsValue({ commissionRate: record.commission_setting.commission_rate });
            }}
          >
            {record.commission_setting.commission_rate}
          </Button>
        );
      },
    },
    {
      title: t('common.createdAt'),
      key: 'created_at',
      render: (text, record) => moment(record.group_order.createdAt).format('LLL'),
    },
    {
      title: t('common.updatedAt'),
      key: 'updated_at',
      render: (text, record) => moment(record.group_order.updatedAt).format('LLL'),
      sorter: true,
    },
  ];

  return (
    <>
      <Search placeholder="id, name, email" allowClear onSearch={onSearch} style={{ width: 300 }} size="small" />
      <PageTitle>{t('common.firebase')}</PageTitle>
      <Table
        // components={{
        //   body: {
        //     cell: EditableCell,
        //   },
        // }}
        // rowClassName="editable-row"
        tableLayout="auto"
        columns={columns}
        dataSource={tableData.data}
        pagination={tableData.pagination}
        loading={tableData.loading}
        onChange={handleTableChange}
        // scroll={{ x: 800 }}
        bordered
      />
      <Modal
        title={'Update: ' + updateFeatureSettingType}
        centered
        open={isFeatureSettingModalOpen}
        onOk={() => handleUpdateFeatureSetting()}
        onCancel={() => {
          setLoading(false);
          setIsFeatureSettingModalOpen(false);
          setFeatureSettingData(initialFeatureSetting);
        }}
        confirmLoading={loading}
      >
        <Spin spinning={loading}>
          <Form name="basic" form={formFeatureSetting}>
            <Form.Item label={'Setting'} name="setting" rules={[{ required: true, message: 'Please select setting!' }]}>
              <Select
                defaultValue={featureSettingData.enable ? 1 : 0}
                style={{ width: 120 }}
                onChange={(value: number) => setFeatureSettingData({ enable: value === 1 })}
                options={[
                  { value: 0, label: 'Disable' },
                  { value: 1, label: 'Enable' },
                ]}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      <Modal
        title={'Promo Tags'}
        centered
        open={isUpdatePromoTagsModalOpen}
        onOk={() => handleUpdatePromoTags()}
        onCancel={() => {
          setLoading(false);
          setIsUpdatePromoTagsModalOpen(false);
          setUpdateDisplaySettingData(initialGroupOrderDisplaySetting);
        }}
        confirmLoading={loading}
      >
        <Spin spinning={loading}>
          <Form name="basic" form={form}>
            <Form.Item
              label={'Promo Tags'}
              name="PromoTags"
              rules={[{ required: true, message: 'Please input Promo Tags!' }]}
            >
              <Input
                placeholder="Promo Tags"
                value={updateDisplaySettingData.displaySetting.promoTags}
                onChange={(e) =>
                  setUpdateDisplaySettingData({
                    ...updateDisplaySettingData,
                    displaySetting: { ...updateDisplaySettingData.displaySetting, promoTags: e.target.value },
                  })
                }
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      <Modal
        title={'Commission Rate'}
        centered
        open={isCommissionRateModalOpen}
        onOk={() => handleCommissionRate()}
        onCancel={() => {
          setLoading(false);
          setIsCommissionRateModalOpen(false);
          setUpdateSellerId('');
        }}
        confirmLoading={loading}
      >
        <Spin spinning={loading}>
          <Form name="basic" form={formCommission}>
            <Form.Item
              label={'Commission Rate'}
              name="commissionRate"
              rules={[
                { required: true, message: 'Please select setting!' },
                {
                  validator: (_, value) => {
                    if (value >= 0 && value < 1) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The value must be greater than or equal to 0 and less than 1!'));
                  },
                },
              ]}
            >
              <Input
                placeholder="Input commission rate"
                value={commissionRateData.rate}
                onChange={(e) => setCommissionRateData({ ...commissionRateData, rate: parseFloat(e.target.value) })}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

import { httpApi } from '@app/api/http.api';
import { LightningDealsModel } from '@app/domain/LightningDealsModel';
import { PageMetaModel, PageOptionsModel } from '@app/domain/PageMetaModel';
import { HomeFeedModel } from '../domain/HomeFeedModel';
export interface HomeFeedResponse {
  meta: PageMetaModel;
  data: HomeFeedModel[];
}
export interface HomeFeedRequest {
  params: PageOptionsModel;
}

export interface updateHomeFeedRequest {
  id?: number;
  title?: string;
  description?: string | null;
  lang?: number;
  type?: number;
  order?: number;
  status?: number;
  content?: string;
  setting?: string | null;
  created_at?: Date;
  updated_at?: Date;
}

export interface createLightningDealsRequest {
  title: string | null;
  discount: number | null;
  tags: string | null;
  store_id: number | null;
  start_at: string | null;
  end_at: string | null;
  file: File | null;
}

export const getHomeFeed = (pagePayload: HomeFeedRequest): Promise<HomeFeedResponse> =>
  httpApi.get<HomeFeedResponse>('v1/admin/customer/home-feed', { ...pagePayload }).then(({ data }) => {
    console.log(data);
    return data as HomeFeedResponse;
  });

export const updateHomeFeed = (Payload: updateHomeFeedRequest): Promise<HomeFeedModel> =>
  httpApi.put<HomeFeedModel>(`v1/admin/customer/home-feed/${Payload.id}`, { ...Payload }).then(({ data }) => {
    console.log(data);
    return data as HomeFeedModel;
  });

export const updateHomeFeedCache = (): Promise<void> => httpApi.delete('/v1/admin/customer/home-feed/cache');

export const createLightningDeals = (Payload: FormData): Promise<LightningDealsModel> =>
  httpApi
    .post<LightningDealsModel>(`v1/admin/recommendation-lightning-deals`, Payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => {
      console.log(data);
      return data as LightningDealsModel;
    });

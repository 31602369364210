import React from 'react';
import * as S from './References.styles';
import { FacebookOutlined, YoutubeOutlined, LinkedinOutlined, InstagramOutlined } from '@ant-design/icons';

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Text>The Best E-commerce Platform for Food Vendors.</S.Text>
      <S.Icons>
        <a href="https://www.youtube.com/@airmart" target="_blank" rel="noreferrer">
          <YoutubeOutlined />
        </a>
        <a href="https://www.instagram.com/goairmart/" target="_blank" rel="noreferrer">
          <InstagramOutlined />
        </a>
        <a href="https://www.facebook.com/profile.php?id=100071926802145" target="_blank" rel="noreferrer">
          <FacebookOutlined />
        </a>
        <a href="https://www.linkedin.com/company/goairmart" target="_blank" rel="noreferrer">
          <LinkedinOutlined />
        </a>
      </S.Icons>
    </S.ReferencesWrapper>
  );
};

import { httpApi } from '@app/api/http.api';
// import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user: UserModel;
}

export interface LoginResponseData {
  id: number;
  email: string;
  display_name: string;
  access_token: string;
  created_at: Date;
  updated_at: Date;
  valid: boolean;
}

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
  httpApi.post<LoginResponseData>('v1/admin/login', { ...loginPayload }).then(({ data }) => {
    console.log(data);
    const res: LoginResponse = {
      token: data.access_token,
      user: {
        id: data.id,
        firstName: data.display_name,
        lastName: ' ',
        imgUrl: '/logo_no_text.svg',
        userName: `@${data.display_name}`,
        email: {
          name: data.email,
          verified: true,
        },
        phone: {
          number: '+18888888888',
          verified: true,
        },
        sex: 'male',
        birthday: '01/26/2022',
        lang: 'en',
        country: 'GB',
        city: 'London',
        address1: '14 London Road',
        zipcode: 5211,
        website: 'goairmart.com',
        socials: {
          twitter: '@Airmart',
          facebook: 'https://www.facebook.com/profile.php?id=100071926802145',
          linkedin: 'https://www.linkedin.com/company/goairmart',
        },
      },
    };
    return res;
  });

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('signUp', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('forgotPassword', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>('setNewPassword', { ...newPasswordData }).then(({ data }) => data);
